.bodyNorm {
  font-size: 18px;
  line-height: 1.55555556;
  font-weight: 400;
}
@media (max-width: 767px) {
  .bodyNorm {
    font-size: 16px;
    line-height: 1.625;
  }
}
.bodySmall {
  font-size: 16px;
  line-height: 1.625;
  font-weight: 400;
}
@media (max-width: 767px) {
  .bodySmall {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: var(--buttonPrimaryBackground);
  font-size: 16px;
  line-height: 1.625;
  font-weight: 700;
  font-family: inherit;
  cursor: pointer;
  color: #fff !important;
  padding: 8px 24px 8px 62px;
  appearance: none;
  border-radius: 10000px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
  position: relative;
  z-index: 2;
  overflow: hidden;
  transition: all 0.24s;
}
@media (max-width: 767px) {
  .button {
    font-size: 14px;
    line-height: 1.71428571;
  }
}
.button:before {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  width: calc(100% - 40px);
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  z-index: -1;
  transform: skew(var(--generalAngle));
  transition: all 0.24s;
}
.button:after {
  content: '';
  position: absolute;
  left: 23px;
  top: 0;
  width: 16px;
  height: 100%;
  background-color: var(--buttonPrimaryOverlay);
  mask-size: 40px 8px;
  mask-position: 100% 50%;
  mask-repeat: no-repeat;
  mask-image: url(/images/arrow-right-large.svg);
  transition: all 0.24s;
}
@media (min-width: 768px) {
  .button:hover {
    color: #007D34 !important;
  }
  .button:hover:before {
    width: 0;
  }
  .button:hover:after {
    width: 26px;
  }
}
@media (max-width: 767px) {
  .button {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
.button--woArrow {
  padding-left: 24px;
  color: #007D34 !important;
}
.button--woArrow:before {
  right: initial;
  left: -10px;
  width: 0;
  background-color: var(--buttonSecondaryOverlay);
}
.button--woArrow:after {
  display: none;
}
@media (min-width: 768px) {
  .button--woArrow:hover:before {
    width: calc(100% + 20px);
  }
}
.section--three .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.section--three .subcontent.subcontent--1 {
  width: 318px;
}
.section--three .subcontent.subcontent--2 {
  width: 886px;
  max-width: calc(100% - (318px + 24px));
}
.area--two .unitTwo {
  width: calc((100% / 3) - 24px);
}
.area--two .unitThree {
  width: calc(25% - 24px);
}
.area--two .unitFour--wide {
  width: calc((100%/1368*798) - 24px);
}
.area--two .unitFour--slim {
  width: calc((100%/1368*570) - 24px);
}
.area--two .unit--imageGallery .part.pict:nth-child(4n-3),
.area--two .unit--imageGallery .part.pict:nth-child(4n) {
  width: calc((100%/1368*570) - 24px);
}
.area--two .unit--imageGallery .part.pict:nth-child(4n-2),
.area--two .unit--imageGallery .part.pict:nth-child(4n-1) {
  width: calc((100%/1368*798) - 24px);
}
/*# sourceMappingURL=./screen-large.css.map */